<template>
  <Page>
    <InvoiceBody ref="body" />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page.js'
import InvoiceBody from '../bodies/Invoice.vue'

export default {
  mixins: [PageMixin],
  components: {
    InvoiceBody
  }
}
</script>
